/**
* General Uppy styles that apply to everything inside the .Uppy container
*/
.uppy-Root {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  position: relative;
  color: #333; }

.uppy-Root *, .uppy-Root *:before, .uppy-Root *:after {
  box-sizing: inherit; }

.uppy-Root [hidden] {
  display: none; }

.UppyIcon {
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
  /* no !important */
  display: inline-block;
  overflow: hidden; }

.uppy-u-reset {
  -webkit-appearance: none;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  background: none;
  border: medium none currentColor;
  border-collapse: separate;
  border-image: none;
  border-radius: 0;
  border-spacing: 0;
  box-shadow: none;
  clear: none;
  cursor: auto;
  display: inline;
  empty-cells: show;
  float: none;
  font-family: inherit;
  font-size: inherit;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  left: auto;
  letter-spacing: normal;
  list-style: none;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  outline: medium none invert;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-origin: 50% 50% 0;
  transform-style: flat;
  transition: none 0s ease 0s;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  z-index: auto; }

.uppy-c-textInput {
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  padding: 6px 8px;
  background-color: #fff; }
  .uppy-size--md .uppy-c-textInput {
    padding: 8px 10px; }
  .uppy-c-textInput:focus {
    border-color: rgba(34, 117, 215, 0.6);
    outline: none;
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.15); }
  [data-uppy-theme="dark"] .uppy-c-textInput {
    background-color: #333;
    border-color: #333;
    color: #eaeaea; }
  [data-uppy-theme="dark"] .uppy-c-textInput:focus {
    border-color: #525252;
    box-shadow: none; }

.uppy-c-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  transition-property: background-color, color;
  transition-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer; }

.uppy-c-btn::-moz-focus-inner {
  border: 0; }

.uppy-c-btn-primary {
  font-size: 14px;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #2275d7;
  color: #fff; }
  .uppy-c-btn-primary:hover {
    background-color: #1b5dab; }
  .uppy-c-btn-primary:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.4); }
  .uppy-size--md .uppy-c-btn-primary {
    padding: 13px 22px; }
  [data-uppy-theme="dark"] .uppy-c-btn-primary {
    color: #eaeaea; }
    [data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
      outline: none; }
    [data-uppy-theme="dark"] .uppy-c-btn-primary::-moz-focus-inner {
      border: 0; }
    [data-uppy-theme="dark"] .uppy-c-btn-primary:focus {
      box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85); }

.uppy-c-btn-link {
  font-size: 14px;
  line-height: 1;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: transparent;
  color: #525252; }
  .uppy-c-btn-link:hover {
    color: #333; }
  .uppy-c-btn-link:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25); }
  .uppy-size--md .uppy-c-btn-link {
    padding: 13px 18px; }
  [data-uppy-theme="dark"] .uppy-c-btn-link {
    color: #eaeaea; }
    [data-uppy-theme="dark"] .uppy-c-btn-link:focus {
      outline: none; }
    [data-uppy-theme="dark"] .uppy-c-btn-link::-moz-focus-inner {
      border: 0; }
    [data-uppy-theme="dark"] .uppy-c-btn-link:focus {
      box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85); }
  [data-uppy-theme="dark"] .uppy-c-btn-link:hover {
    color: #939393; }

.uppy-c-btn--small {
  font-size: 0.9em;
  padding: 7px 16px;
  border-radius: 2px; }
  .uppy-size--md .uppy-c-btn--small {
    padding: 8px 10px;
    border-radius: 2px; }
