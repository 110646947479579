@import '@uppy/core/dist/style.css';
@import '@uppy/dashboard/dist/style.css';

$border: #ccc;

* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
}
